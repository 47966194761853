$(document).ready(function() {

	$("[id^='faq-question_']").on('click' , function() {
		id_question = this.id.split('_')[1];
		// console.log(id_question);

		$("[id^='faq-question_']").each(function(index) {
			if ($(this).hasClass('active')) {
				$(this).removeClass('active');
			}
		});

		$(this).addClass('active');

		$("[id^='faq-response_']").each(function(index) {
			if ($(this).hasClass('d-block')) {
				$(this).removeClass('d-block').addClass('d-none');
			}
		});

		$('#faq-response_'+id_question).removeClass('d-none').addClass('d-block');

		// console.log($(window).width());

		if ($(window).width() < 767.98) {
			$('html,body').animate({scrollTop: $("#faq-response_"+id_question).offset().top - 100 }, 'slow'      );
		}

	});

	$("[id^='question-question_']").on('click' , function() {
		id_question = this.id.split('_')[1];
		// console.log(id_question);

		// $("[id^='faq-question_']").each(function(index) {
		// 	if ($(this).hasClass('active')) {
		// 		$(this).removeClass('active');
		// 	}
		// });
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
			$('#question-reponse_'+id_question).removeClass('d-block').addClass('d-none');
		} else {
			$(this).addClass('active');
			$('#question-reponse_'+id_question).removeClass('d-none').addClass('d-block');
		}

		// $("[id^='faq-response_']").each(function(index) {
		// 	if ($(this).hasClass('d-block')) {
		// 		$(this).removeClass('d-block').addClass('d-none');
		// 	}
		// });

		// $('#question-reponse_'+id_question).removeClass('d-none').addClass('d-block');

		// console.log($(window).width());

		// if ($(window).width() < 767.98) {
		// 	$('html,body').animate({scrollTop: $("#faq-response_"+id_question).offset().top - 100 }, 'slow'      );
		// }

	});

});