// var siteURL = (function() {
//     var scripts = document.getElementsByTagName('script');
//     var index = scripts.length - 1;
//     var myScript = scripts[index];
//     return myScript.src.replace("assets/js/site.min.js", "");
// })();
var siteURL = (function() { return $('#get_host').html();})();

$.fn.pressEnter = function(fn) {  
    return this.each(function() {  
        $(this).bind('enterPress', fn);
        $(this).keyup(function(e){
            if(e.keyCode == 13)
            {
              $(this).trigger("enterPress");
            }
        })
    });  
};

if (!String.prototype.includes) {
    String.prototype.includes = function() {
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
    };
}

// Mets à jour le contenu de la page de recherche dynamiquement
function updatePage(optionsRecherche) {
    if (typeof updating !== 'undefined') { updating.abort(); }
    // $('html,body').animate({scrollTop: $('#backtotop').offset().top - $('.navbar').height()}, 'slow');
    $.post(siteURL + "page_results_text_title/"+optionsRecherche['marque'], function(data, status) {
        // On rajoute le retour dans la page
        $('#vehicules_text_title').show(function() {
            $(this).html(data).show(function() {});
        });
    });
    $.post(siteURL + "page_results_text_description/"+optionsRecherche['marque'], function(data, status) {
        // On rajoute le retour dans la page
        $('#vehicules_text_description').show(function() {
            $(this).html(data).show(function() {});
        });
    });
    $.post(siteURL + "page_results_text_top/"+optionsRecherche['marque'], function(data, status) {
        // On rajoute le retour dans la page
        $('#vehicules_text_top').fadeOut("normal", function() {
            $(this).html(data).fadeIn('normal', function() {});
        });
    });
    $.post(siteURL + "page_results_text_offre/"+optionsRecherche['marque'], optionsRecherche, function(data, status) {
        // On rajoute le retour dans la page
        $('#vehicules_text_offre').fadeOut("normal", function() {
            $(this).html(data).fadeIn('normal', function() {});
        });
    });
    $.post(siteURL + "page_results_text_bottom/"+optionsRecherche['marque'], function(data, status) {
        // On rajoute le retour dans la page
        $('#vehicules_text_bottom').fadeOut("normal", function() {
            $(this).html(data).fadeIn('normal', function() {});
        });
    });

    // Appel ajax à une requête de type POST pour récupérer le html d'une page de résultat
    $('#results').fadeOut();
    updating = $.post(siteURL + "page_results", optionsRecherche, function(data, status) {
        // On rajoute le retour dans la page
        $('#page_vehicules_results').fadeOut("normal", function() {
            $(this).html(data).fadeIn('normal', function() {
                if ($("#nombre_results").html()) {
                    pluriel = ($('#nombre_results').html() > 1 ? 's' : '');
                    $("#search_nombre_vehicules").html($('#nombre_results').html() + " v&eacute;hicule" + pluriel).show();
                }
                var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

                // Scroll vers le haut de la page
                updatePagination(optionsRecherche['page']);
                updateURL(optionsRecherche);
                updateLifeLine(optionsRecherche);
                updateSEO(optionsRecherche);
                $('#results').fadeIn();
            });
        });
    })
    // On renvoie la variable optionsRecherche mise à jour
    return optionsRecherche;
}

// Mets à jour l'url du navigateur
function updateURL(optionsRecherche) {
    // Supprime le "/" final de l'url courante
    if (window.location.href.endsWith("/")) { new_url = window.location.href.substring(0, window.location.href.length - 1); } else { new_url = window.location.href; }
    
    // On supprime toutes les valeurs passées en url précédement
    new_url = new_url.replace(/(\?options=\[)(.*)(\])/,"").replace("/vehicules-leasing",'').replace("#", "").replace(/(\/en-)([a-z-0-9\-])+/, "").replace(/(\/page)\/(\d)+/, "").replace(/(\/marque)([a-z-0-9])+/, "").replace(/(\/modele)([a-z-0-9])+/, "").replace(/(\/carrosserie)(.)+/, "").replace(/(\/motorisation)([a-z-0-9])+/, "").replace("/vente-flash", "");
    // On initialise plusieurs valeurs vides qu'on remplacera plus tart
    new_marque = new_modele = new_venteflash = emplacement_rewrite = '';

    // Si on est dans le cas d'une mise à jour dynamique
    if (typeof optionsRecherche !== "undefined") {


        search_string = string = '';
        for (option in optionsRecherche) {
            if (option != 'page' && option != 'marque' && option != 'modele') {
                string = string + option+':'+optionsRecherche[option]+';';
            }
        }
        if (string != '') {
            search_string = '?options=['+string+']';
        }
        new_etat = "/vehicules-leasing";
        
        if (typeof optionsRecherche['marque'] !== "undefined" ) {
            new_marque = "/marque-" + $("#search_marque > option[value=marque_" + optionsRecherche['marque'] + "]").html().replace(" ", "-").toLowerCase();
        }
        if (typeof optionsRecherche['modele'] !== "undefined" ) {
            modele_rewrite = $("#search_modele > option[value=" +  optionsRecherche['modele'] + "]").html().split("&nbsp;")[0].toLowerCase().replace(/ /g,"-");
            new_modele = "/modele-" + modele_rewrite;
        }
        updateNavbar(optionsRecherche);
    }
    // Si l'on provient d'une recherche de la page d'accueil ou d'une url rewritée
    else {
        if ($('#fromsearch_marque').length > 0 ) {
            new_marque = "/marque-" + $("#search_marque > option[value=marque_" + $('#fromsearch_marque').html() + "]").html().toLowerCase();
        }
        if ($('#fromsearch_modele').length > 0 ) {
            if ($("#search_modele > option[value=" +  $('#fromsearch_modele').html() + "]").length > 0 ) {
                modele_rewrite = $("#search_modele > option[value=" +  $('#fromsearch_modele').html() + "]").html().split("&nbsp;")[0].toLowerCase().replace(/ /g,"-");
                new_modele = "/modele-" + modele_rewrite;
            }
        }
    }

    new_url = new_url.replace(/(\/page)\/(\d)+/, "");
    // Cas avec pagination
    if (optionsRecherche['page'] != 1) {
        window.history.pushState('page-'+$('#current_page').html(), '', new_url+new_etat+emplacement_rewrite+new_marque+new_modele+'/page/'+$('#current_page').html()+search_string);
    }
    // Cas sans pagination
    else {
        window.history.pushState('page-'+$('#current_page').html(), '', new_url+new_etat+emplacement_rewrite+new_marque+new_modele+search_string);
    }
}

function updateSEO(optionsRecherche) {
    baseURL = siteURL+'vehicules-leasing';

    // Si on est dans le cas d'une mise à jour dynamique
    if (typeof optionsRecherche !== "undefined") {
        if (optionsRecherche['marque']) {
            if (optionsRecherche['modele']) {
                new_modele = $("#search_modele > option[value=" +  optionsRecherche['modele'] + "]").html().split("&nbsp;")[0];
            }
            new_marque = $("#search_marque > option[value=marque_" + optionsRecherche['marque'] + "]").html();
            $('#search_seo_titre').html("Nous vous proposons une séléction de véhicules " + new_marque + " " + new_modele +" en leasing & LLD");
            if (new_modele) {
                $('#search_seo_modele').html(" tel que <strong>" +new_modele+"</strong>");
                $('#search_seo_marque_modele').html(" choix de "+new_marque+ " "+new_modele);
                $('.search_seo_bottom_marque').each( function () {
                    $(this).html(new_marque + " " + new_modele + " ")
                })
                $('#search_seo_bottom_example').html(", par exemple " +new_marque + " " +new_modele);
            }
            else {
                $('#search_seo_modele').html("");
                $('#search_seo_marque_modele').html(" choix de "+new_marque+ " ");
                $('.search_seo_bottom_marque').each( function () {
                    $(this).html(new_marque + " ")
                })
                $('#search_seo_bottom_example').html(", par exemple " +new_marque);
            }
            listing_modeles = "";
            $.post(siteURL+"json/modeles", optionsRecherche, function(data, status) {
                counter = 0;
                for (id in data) {
                    counter++;
                    if (data[id] != '') {
                        // Modele friendly
                        modele_url = data[id].toLowerCase().replace(/[^A-Z0-9]+/ig,"-");
                        if (modele_url[modele_url.length-1] == "-") {
                            modele_url = modele_url.slice(0, -1);
                        }
                        listing_modeles += '<a href="' +siteURL + 'vehicules-leasing/marque-' + new_marque.replace(" ", "-").toLowerCase() + '/modele-' + modele_url +'" title="'+new_marque+' '+data[id]+' en leasing LLD à Lyon &middot; Click & Lease">'+data[id]+'</a>';
                        if (counter < Object.keys(data).length) { listing_modeles += ', ';} else { listing_modeles += '.';}
                    }
                };
                $('#search_seo_listing').html('<h2 style="font-size: 17px;"> Quels modèles de ' + new_marque +' est-il possible d\'obtenir en location longue durée (LLD) ?</h2><p style="font-size:13px; text-align:justify;">Selon les arrivages vous pouvez trouver chez Click & Lease ces modèles ' + new_marque + ' : '+ listing_modeles +' <br/> Bien sûr d\'autres marques sont aussi disponibles en leasing, avec une location à longue durée.</p>');
            });

            $('#search_seo_marque').html(" de marque "+new_marque);
            $('#search_seo_notmarque').html("");
        }
        else {
            $('#search_seo_titre').html("Nous vous proposons une sélection complète multimarques de voitures en leasing & LLD");
            $('#search_seo_marque').html("");
            $('#search_seo_notmarque').html("parmi les plus grandes marques");
            $('#search_seo_marque_modele').html("");
            $('#search_seo_marque_modele').html(" large gamme de voitures");
            $('#search_seo_listing').html("");
        }
        
    }
}

function updateLifeLine(optionsRecherche) {
    baseURL = siteURL+'vehicules-leasing';
    $('#lifeline_marque').attr('href', '');
    $('#lifeline_marque').attr('title', "");
    $('#lifeline_marque').html("");
    $('#lifeline_leasing').hide();
    $('#lifeline_modele').attr('href', baseURL);
    $('#lifeline_modele').attr('title', "Nos véhicules neufs et occasions en LLD");
    $('#lifeline_modele').html("NEUF ET OCCASION");
    
    if (optionsRecherche['marque']) {
        new_marque = $("#search_marque > option[value=marque_" + optionsRecherche['marque'] + "]").html().toLowerCase();
        $('#lifeline_marque').html(new_marque);
        $('#lifeline_marque').attr('href', baseURL+'/marque-'+new_marque.replace(" ", "-"));
        $('#lifeline_marque').attr('title', "Nos véhicules "+ new_marque.toUpperCase() + " en leasing");
        $('#lifeline_leasing').show();
        if (!optionsRecherche['modele']) {
                $('#lifeline_modele').attr('title', "Nos véhicules "+ new_marque.toUpperCase() + " neufs et occasions en LLD");
                $('#lifeline_modele').attr('href', baseURL+'/marque-'+new_marque.replace(" ", "-"));

        }
    }
    if (optionsRecherche['modele']) {
        new_modele = $("#search_modele > option[value=" +  optionsRecherche['modele'] + "]").html().split("&nbsp;")[0].toLowerCase();
        $('#lifeline_modele').html(new_modele);
        $('#lifeline_modele').attr('href', baseURL+'/marque-'+new_marque.replace(" ", "-")+"/modele-"+new_modele.replace(/ /g,"-"));
        $('#lifeline_modele').attr('title', "Nos véhicules "+ new_marque.toUpperCase() + " " + new_modele.toUpperCase() + " neufs et occasions en LLD");
    }

}
// Mets à jour la pagination
function updatePagination(currentPageRequested) {
    var first = '<li id="paginationsearch_previous" class="rounded-left"><span>&lsaquo;</span></li> ';
    var firstbottom = '<li id="paginationsearchbottom_previous" class="rounded-left"><span>&lsaquo;</span></li> ';
    var last = '<li id="paginationsearch_next" class="rounded-right"><span>&rsaquo;</span></li>';
    var lastbottom = '<li id="paginationsearchbottom_next" class="rounded-right"><span>&rsaquo;</span></li>';
    var total_pages = $("#nombre_pages").html();


    $("#paginationsearch, #paginationsearchbottom").empty();
    $("#paginationsearch").append(first);
    $("#paginationsearchbottom").append(firstbottom);

    // Pour le début des pages
    if (parseInt(currentPageRequested) <= 4) {
        for (i = 1; i <= 5; i++) {
            $("#paginationsearch").append("<li id=\"paginationsearch_" + i + "\"><span>" + i + "</span></li> ");
            $("#paginationsearchbottom").append("<li id=\"paginationsearchbottom_" + i + "\"><span>" + i + "</span></li> ");
        }
    }
    // Quand on arrive à la fin des pages
    else if (parseInt(currentPageRequested) > parseInt(total_pages) - 3) {
        for (i = parseInt(total_pages) - 4; i <= parseInt(total_pages); i++) {
            $("#paginationsearch").append("<li id=\"paginationsearch_" + i + "\"><span>" + i + "</span></li> ");
            $("#paginationsearchbottom").append("<li id=\"paginationsearchbottom_" + i + "\"><span>" + i + "</span></li> ");
        }
    }
    /// Bah ... au milieu quoi ...
    else {
        for (i = parseInt(currentPageRequested) - 2; i <= parseInt(currentPageRequested) + 2; i++) {
            $("#paginationsearch").append("<li id=\"paginationsearch_" + i + "\"><span>" + i + "</span></li> ");
            $("#paginationsearchbottom").append("<li id=\"paginationsearchbottom_" + i + "\"><span>" + i + "</span></li> ");
        }
    }
    $("#paginationsearch").append(last);
    $("#paginationsearchbottom").append(lastbottom);

    // Ne s'applique pas aux premiers et derniers elements (donc _previous et _next)
    $("li[id^='paginationsearch_']:not(:first):not(:last)").each(function() {
        pagination_id = this.id.replace('paginationsearch_', '');
        // Si la case de pagination est supérieurs au nombre de pages de la requête
        if (parseInt(pagination_id) > parseInt(total_pages)) {
            // On la rends inactive
            $(this).addClass('inactive');
        }
    });
    $("li[id^='paginationsearchbottom_']:not(:first):not(:last)").each(function() {
        pagination_id = this.id.replace('paginationsearchbottom_', '');
        // Si la case de pagination est supérieurs au nombre de pages de la requête
        if (parseInt(pagination_id) > parseInt(total_pages)) {
            // On la rends inactive
            $(this).addClass('inactive');
        }
    });

    // On passe la page courante en active dans la pagination
    $('li[id="paginationsearch_' + currentPageRequested + '"], li[id="paginationsearchbottom_' + currentPageRequested + '"]').addClass('active');

    // Si nous sommes à la dernière page on rends _next inactif
    if (parseInt(currentPageRequested) == parseInt(total_pages) || parseInt(total_pages) == 0) {
        $('li[id="paginationsearch_next"], li[id="paginationsearchbottom_next"]').addClass('disabled').addClass('inactive');
    }
    // Si nous sommes à la première page on rends _previous inactif
    if (parseInt(currentPageRequested) == 1) {
        $('li[id="paginationsearch_previous"], li[id="paginationsearchbottom_previous"]').addClass('disabled').addClass('inactive');
    }
    $('li[id^="paginationsearch_"]').each( function() {
        idPage = this.id.replace('paginationsearch_', '');
        if (idPage === parseInt(idPage, 10) && idPage > total_pages) {
            $(this).addClass('disabled').addClass('inactive');
        }
    })
    $('li[id^="paginationsearchbottom_"]').each( function() {
        idPage = this.id.replace('paginationsearchbottom_', '');
        if (idPage === parseInt(idPage, 10) && idPage > total_pages) {
            $(this).addClass('disabled').addClass('inactive');
        }
    })
}

// Mets à jour l'url du navigateur
function updateNavbar(optionsRecherche) {
    
    // Si on est dans le cas d'une mise à jour dynamique
    if (typeof optionsRecherche !== "undefined") {
        if (optionsRecherche['page'] != 1) {
            pageTitle = " - page " + optionsRecherche['page'];
        }
        else {
            pageTitle = "";
        }
        if (typeof optionsRecherche['marque'] !== "undefined" ) {
            if (typeof optionsRecherche['modele'] !== "undefined") {
                document.title = $("#search_marque > option[value=marque_" + optionsRecherche['marque'] + "]").html().replace(" ", "-") + " " + $("#search_modele > option[value=" +  optionsRecherche['modele'] + "]").html().split("&nbsp;")[0].replace(/ /g,"-") + " en location longue durée " + pageTitle + " · Click & Lease";
            }
            else {
                document.title = "Leasing "+ $("#search_marque > option[value=marque_" + optionsRecherche['marque'] + "]").html().replace(" ", "-") + pageTitle + " avec ou sans apport · Click & Lease";
            }
        }
        else {
            document.title = "Leasing auto - Financement - Location Longue Durée(LLD) - Click & Lease";
        }
    }
}

// À utiliser dans la console du navigateur : encodeEmail('adresse@email.com', '123') avec le second argument étant un chiffre entre 0 et 255
// afin de récupérer un hash de l'adresse mail à décoder ensuite avec decodeEmail() ci dessous.
// https://andrewlock.net/simple-obfuscation-of-email-addresses-using-javascript/
function encodeEmail(email, key) {
    var encodedKey = key.toString(16);
    var encodedString = make2DigitsLong(encodedKey);
    for(var n=0; n < email.length; n++) {
        var charCode = email.charCodeAt(n);
        var encoded = charCode ^ key;
        var value = encoded.toString(16);
        encodedString += make2DigitsLong(value);
    }
    return encodedString;
}

function decodeEmail(encodedString) {
    var email = ""; 
    var keyInHex = encodedString.substr(0, 2);
    var key = parseInt(keyInHex, 16);
    for (var n = 2; n < encodedString.length; n += 2) {
        var charInHex = encodedString.substr(n, 2)
        var char = parseInt(charInHex, 16);
        var output = char ^ key;
        email += String.fromCharCode(output);
    }
    return email;
}

function make2DigitsLong(value){
    return value.length === 1 ? '0' + value : value;
}

// popover header num telephone

$("nav.navbar").hover(
    function(){},
    function(){
        $("div.navbar-toggle").addClass("d-none");
    }
)

$("span.tel-text").hover(
    function(){
        $("div.navbar-toggle").css("top", $("nav.navbar").height());
        $("div.navbar-toggle").removeClass("d-none");
    },
    function(){}
)

$("button.navbar-toggler").click(
    function(){
        $("div.navbar-toggle").addClass("d-none");
    }
)

// Indicateur de scroll page article
$(document).on("scroll", function(){
    var pixels = $(document).scrollTop();
    var pageHeight = $(document).height() - $(window).height();
    var progress = 100 * pixels / pageHeight;

    $("div.scroll-indicator-article").css("width", progress + "%");
})

// Switch stats page home part/pro
$("div.lld-par").click(function(){
    $("div.lld-par").removeClass("pro-part-inactive");
    $("div.lld-par").addClass("pro-part-active");
    $("div.lld-pro").removeClass("pro-part-active");
    $("div.lld-pro").addClass("pro-part-inactive");
    $("div.lld-pro-content").addClass("d-none");
    $("div.lld-par-content").removeClass("d-none");
})

$("div.lld-pro").click(function(){
    $("div.lld-pro").removeClass("pro-part-inactive");
    $("div.lld-pro").addClass("pro-part-active");
    $("div.lld-par").removeClass("pro-part-active");
    $("div.lld-par").addClass("pro-part-inactive");
    $("div.lld-par-content").addClass("d-none");
    $("div.lld-pro-content").removeClass("d-none");
})

// modal
$("#btn-modal-recall").on('click', function() {
    $('#statut-success').hide();
    $('#statut-base').show();
    $('#header-contact-telephone').val('');
});
// format input telephone
function phoneMask() { 
    var num = $( "#header-contact-telephone").val().replace(/\D/g,''); 
    $( "#header-contact-telephone").val(num.substring(0,2) + ' ' + num.substring(2,4) + ' '+ num.substring(4,6) + ' ' + num.substring(6,8) + ' ' + num.substring(8,10)); 
}
$('[type="tel"]').keyup(phoneMask);