$(document).ready(function() {

// Initialisation de variables
var optionsRecherche = { 'page': 1 };

// "Hacks" pour requêtes en provenance de la recherche
if ($("#fromsearch_marque").length > 0) {
    optionsRecherche['marque'] = $('#fromsearch_marque').html();
    $("#search_marque > option[value=marque_" + $('#fromsearch_marque').html() + "]").attr('selected','selected');
    if ($("#fromsearch_modele").length == 0) {
        $.post(siteURL+"json/modeles/available", optionsRecherche, function(data, status) {
            $("#search_modele").prop('disabled', false);
            $("#search_modele").empty();
            $("#search_modele").append('<option>Mod&egrave;les</option>');  
            for (id in data) {
                if (data[id]['nb'] > 0) {
                    $("#search_modele").append('<option value=' + data[id]['id'] + ">" + id + "&nbsp;&nbsp;&nbsp;(" + data[id]['nb'] + ")</option>");
                }
                else {
                    $("#search_modele").prop('disabled', true);
                }
            };
        });
    }
}


if ($("#fromsearch_prixmensuel_min").length > 0) {
    optionsRecherche['prixmensuel_min'] = $('#fromsearch_prixmensuel_min').html();
}
if ($("#fromsearch_prixmensuel_max").length > 0) {
    optionsRecherche['prixmensuel_max'] = $('#fromsearch_prixmensuel_max').html();
}

if ($("#fromsearch_millesime").length > 0) {
    optionsRecherche['millesime'] = $('#fromsearch_millesime').html();
    $("#search_millesime > option[value=" + $('#fromsearch_millesime').html() + "]").attr('selected','selected');
}
if ($("#fromsearch_motorisation").length > 0) {
    optionsRecherche['motorisation'] = $('#fromsearch_motorisation').html();
    $("#search_motorisation > option[value=" + $('#fromsearch_motorisation').html() + "]").attr('selected','selected');
    $("#search_motorisation").addClass('search_selected');
}
if ($("#fromsearch_carrosserie").length > 0) {
    optionsRecherche['carrosserie'] = $('#fromsearch_carrosserie').html();
    $("#search_carrosserie > option[value=" + $('#fromsearch_carrosserie').html() + "]").attr('selected','selected');
    $("#search_carrosserie").addClass('search_selected');
}
if ($("#fromsearch_boite").length > 0) {
    optionsRecherche['boite'] = $('#fromsearch_boite').html();
    $("#search_boite > option[value=boite_" + $('#fromsearch_boite').html() + "]").attr('selected','selected');
    $("#search_boite").addClass('search_selected');
}
if ($("#fromsearch_vo").length > 0) {
    optionsRecherche['vo'] = $('#fromsearch_vo').html();
    $("#search_vo > option[value=" + $('#fromsearch_vo').html() + "]").attr('selected','selected');
    $("#search_vo").addClass('search_selected');
}
if ($("#fromsearch_couleur").length > 0) {
    optionsRecherche['couleur'] = $('#fromsearch_couleur').html();
    $("#search_couleur_" + $('#fromsearch_couleur').html()).addClass('search_selected');
}
if ($("#fromsearch_kilometrage").length > 0) {
    optionsRecherche['kilometrage'] = $('#fromsearch_kilometrage').html();
}
if ($("#fromsearch_modele").length > 0) {
    optionsRecherche['modele'] = $('#fromsearch_modele').html();
    $.post(siteURL+"json/modeles/available", optionsRecherche, function(data, status) {
        $("#search_modele").prop('disabled', false);
        $("#search_modele").empty();
        $("#search_modele").append('<option>Mod&egrave;les</option>');
        for (id in data) {
            if (data[id]['nb'] > 0) {
                if (data[id]['id'] == optionsRecherche['modele']) { modele_selected = ' selected';} else { modele_selected = '';}
                $("#search_modele").append('<option value=' + data[id]['id'] + modele_selected + ">" + id + "&nbsp;&nbsp;&nbsp;(" + data[id]['nb'] + ")</option>");
            }
        };
        if ($("#search_modele > option[value=" + optionsRecherche['modele'] + "]").length > 0) {
            $("#search_modele").addClass('search_selected');
        }
    });
}

if ($("#fromsearch_query").length > 0) {
    optionsRecherche['search_string'] = $('#fromsearch_query').html().replace(',', ' ');
    $("#search_query").addClass('search_selected');
} else {
    delete(optionsRecherche["search_string"]);
}

// Sinon rewrite l'ensemble des URL
if ($('#page_vehicules_results').length > 0) {
    updateURL(optionsRecherche);
}


// Recherche par Couleur
$("div[id^='search_couleur_']").on('click', function() {
    var couleur = this.id.replace('search_couleur_', '');
    if ($(this).hasClass('search_selected')) {
        $(this).removeClass('search_selected');
        delete(optionsRecherche['couleur']);
    } else {
        $("div[id^='search_couleur_']").each(function() {
            if (this.id.replace('search_couleur_', '') != couleur) {
                $(this).removeClass('search_selected')
            } else {
                $(this).addClass('search_selected');
            }
        });
        optionsRecherche['couleur'] = couleur;
    }
    optionsRecherche['page'] = 1;
    updatePage(optionsRecherche);
})
// Recherche par Marque
$("#search_marque").on('change', function() {
    optionsRecherche['page'] = 1;
    delete(optionsRecherche['modele']);
    if ($("#search_marque").val() == "Marques") {
        delete(optionsRecherche['marque']);

        $("#search_modele").removeClass('search_selected');
        $("#search_modele").empty();
        $("#search_modele").prop('disabled', true);
        $("#search_modele").append('<option>Mod&egrave;les</option>\n<option disabled="">Veuillez s&eacute;lectionner une marque</option>');

        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['marque'] = $("#search_marque").val().replace('marque_', '');
        $.post(siteURL+"json/modeles/available", optionsRecherche, function(data, status) {
            $("#search_modele").removeClass('search_selected');
            $("#search_modele").empty();
            $("#search_modele").append('<option>Mod&egrave;les</option>');
            $("#search_modele").prop('disabled', false);
            for (id in data) {
                if (data[id]['nb'] > 0) {
                    $("#search_modele").append('<option value=' + data[id]['id'] + ">" + id + "&nbsp;&nbsp;&nbsp;(" + data[id]['nb'] + ")</option>");
                }
            };
        });
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});

restore = 0;
$("#search_annonce").on('keyup', function(e) {
    // Si le champ value est un chiffre et que l'appui sur la touche est différent de "SUPPR"
    // && ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) ) 
    if ($("#search_annonce").val().length > 4 && $.isNumeric($("#search_annonce").val()) == true) {
        //console.log($("#search_annonce").val());
        $(this).removeClass('search_error');   
            optionsRecherche['page'] = 1;
            delete(optionsRecherche['id']);
            optionsRecherche['id'] = $("#search_annonce").val();
            updatePage(optionsRecherche);
            restore = 0;
        $(this).addClass('search_selected');
    }
    // Si l'appui est sur "SUPPR/BACKSPACE" ou que la value n'est pas un chiffre
    else {
        if ((e.keyCode == 8 || e.keyCode == 46) && $("#search_annonce").val().length < 5 && restore == 0) {
            $(this).removeClass('search_selected');
            $(this).removeClass('search_error');
            optionsRecherche['page'] = 1;
            delete(optionsRecherche['id']);
            updatePage(optionsRecherche);
            restore = 1;
        }
        else {
            $(this).addClass('search_error');
        }
    }
});

// Gère l'effet de zoom sur les photos d'annonce
$('#search_annonce').focus(function() {
    $(this).addClass('focus')
}).blur(function() {
    $(this).removeClass('focus')
})

// Recherche par Modèle
$("body").on('change', "#search_modele", function() {
    optionsRecherche['page'] = 1;
    if (parseInt($("#search_modele").val()) > 0 == false) {
        delete(optionsRecherche['modele']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['modele'] = $("#search_modele").val();
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Millesime
$("#search_millesime").on('change', function() {
    optionsRecherche['page'] = 1;
    if (parseInt($("#search_millesime").val()) > 0 == false) {
        delete(optionsRecherche['millesime']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['millesime'] = $("#search_millesime").val();
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Emplacement
$("#search_emplacement").on('change', function() {
    optionsRecherche['page'] = 1;
    if ($("#search_emplacement").val() == "EN STOCK") {
        delete(optionsRecherche['emplacement']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['emplacement'] = $("#search_emplacement").val();
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Carrosserie
$("#search_carrosserie").on('change', function() {
    optionsRecherche['page'] = 1;
    if ($("#search_carrosserie").val() == "CARROSSERIE") {
        delete(optionsRecherche['carrosserie']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['carrosserie'] = $("#search_carrosserie").val();
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Type de Motorisation
$("#search_motorisation").on('change', function() {
    optionsRecherche['page'] = 1;
    if ($("#search_motorisation").val() == "ÉNERGIE") {
        delete(optionsRecherche['motorisation']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['motorisation'] = $("#search_motorisation").val().replace('moteur_', '');
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Kilométrage
$("#search_kilometrage").on('change', function() {
    optionsRecherche['page'] = 1;
    parseInt($("#search_kilometrage").val());
    if (parseInt($("#search_kilometrage").val().replace('km_', '')) > 0 == false) {
        delete(optionsRecherche['kilometrage']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['kilometrage'] = $("#search_kilometrage").val().replace('km_', '');
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Type de Boîte de Vitesses
$("#search_boite").on('change', function() {
    optionsRecherche['page'] = 1;
    if ($("#search_boite").val().indexOf("bv") == -1) {
        delete(optionsRecherche['boite']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['boite'] = $("#search_boite").val().replace('boite_', '').toUpperCase();
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});
// Recherche par Prix (mensuel et ttc)
$('body').on('touchend mouseup', function() {
    if (typeof startclickslider !== 'undefined' && startclickslider == 1) {
        if (eventclicked) {
            $("#search_prixpublic_max").val(parseInt(valueclicked[1]));
            $(".min-max-ttc .float-right").html(parseInt(valueclicked[1]) + ' &euro;');
        } else {
            $("#search_prixpublic_min").val(parseInt(valueclicked[0]));
            $(".min-max-ttc .float-left").html(parseInt(valueclicked[0]) + ' &euro;');
        }
        if ($("#prixttc").is(":visible")) {
            valuelow = $('.prixttc-handle-lower').attr('aria-valuenow');
            valuehigh = $('.prixttc-handle-upper').attr('aria-valuenow');
            optionsRecherche['page'] = 1;
            delete(optionsRecherche['prixmensuel_min']);
            delete(optionsRecherche['prixmensuel_max']);
            optionsRecherche['prix_min'] = parseInt(valuelow);
            optionsRecherche['prix_max'] = parseInt(valuehigh);
            updatePage(optionsRecherche);
            startclickslider = 0;
            }
        else if ($("#prixmensuel").is(":visible")) {
            valuelow = $('.prixmensuel-handle-lower').attr('aria-valuenow');
            valuehigh = $('.prixmensuel-handle-upper').attr('aria-valuenow');
            optionsRecherche['page'] = 1;
            delete(optionsRecherche['prix_min']);
            delete(optionsRecherche['prix_max']);
            optionsRecherche['prixmensuel_min'] = parseInt(valuelow);
            optionsRecherche['prixmensuel_max'] = parseInt(valuehigh);
            updatePage(optionsRecherche);
            startclickslider = 0;
            }
        }
});

// Recherche par véhicule neuf ou d'occasion
$("#search_etat").on('change', function() {
    optionsRecherche['page'] = 1;
    if ($("#search_etat").val().indexOf("v") == -1) {
        delete(optionsRecherche['vo']);
        updatePage(optionsRecherche);
        $(this).removeClass('search_selected');
    } else {
        optionsRecherche['vo'] = $("#search_etat").val();
        updatePage(optionsRecherche);
        $(this).addClass('search_selected');
    }
});

// À l'ajout d'un tagsinput
$('#search_query').on('itemAdded', function(event) {
  if (typeof optionsRecherche['search_string'] === "undefined") { optionsRecherche['search_string'] = ''; }
  optionsRecherche['search_string'] = (optionsRecherche['search_string'] + ' ' + event.item + ' ').replace(',', '').trim();
  //$('.bootstrap-tagsinput').addClass('search_selected');
  $('#mobile_search_query').val(optionsRecherche['search_string']);
  optionsRecherche = updatePage(optionsRecherche);
});

// À la suppreesion d'un tagsinput
$('#search_query').on('itemRemoved', function(event) {
  if ($(this).val() == "") {
      delete(optionsRecherche['search_string']);
      //$('.bootstrap-tagsinput').removeClass('search_selected');
  } else {
      optionsRecherche['search_string'] = optionsRecherche['search_string'].replace(event.item, '').replace(',', '').trim();
  }
  optionsRecherche = updatePage(optionsRecherche);
});

// Gestion des Filtres de recherche
$("[id^='tri_']").parent().on('click', function() {
    tri = $(this).find("span").attr('id').replace('tri_', '').split('_');
    if (tri[1] == 'asc') { var arrow = ' croissant'; } else { var arrow = ' décroissant'; }
    switch (tri[0]) {
        case 'km':
            optionsRecherche['sortby'] = "Kilometrage";
            $('#filtre_tri, #filtre_tribottom').html("Kilom&eacute;trage" + arrow + "<i style='margin-top: 4px; min-width:0px;' class='fas fa-chevron-down float-right'></i>");
            break;
        case 'millesime':
            optionsRecherche['sortby'] = "Millesime";
            $('#filtre_tri, #filtre_tribottom').html("Ann&eacute;e" + arrow + "e<i style='margin-top: 4px; min-width:0px;' class='fas fa-chevron-down float-right'></i>");
            break;
        case 'mensualite':
            optionsRecherche['sortby'] = "PrixMensuel";
            $('#filtre_tri, #filtre_tribottom').html("Loyer" + arrow + "<i style='margin-top: 4px; min-width:0px;' class='fas fa-chevron-down float-right'></i>");
            break;
    }
    optionsRecherche['sortorder'] = tri[1].toUpperCase();
    optionsRecherche['page'] = 1;
    updatePage(optionsRecherche);
    return false;
});

// Réinitialise les champs de recherche au click sur l'id de reset
//$("#reset").on('click', function() { window.location = window.location.origin + window.location.pathname});

$("#reset").on('click', function() {
    optionsRecherche = { 'page': 1 };
    updatePage(optionsRecherche);
    $("[id^='search_'] > option").removeAttr('selected');
    $(".search_selected").removeClass('search_selected');
    $("[id^='search_']").prop('selectedIndex',0);
    $("#search_modele").empty();
    $("#search_modele").append('<option>Mod&egrave;les</option>');  
    $("#search_modele").attr('disabled', true);

    $('#mobile_search_query').val("");
    $('#mobile_search_submit').click();
    $('.bootstrap-tagsinput > input').val("");
    $('.bootstrap-tagsinput > input').trigger(jQuery.Event('keypress', { keycode: 13 }));
    $('.badge > span').each( function() { $(this).click();});

    slidermensuel.noUiSlider.reset();
});


// Mets à jour la pagination de la page courante
if ($('#current_page').length > 0) {updatePagination($('#current_page').html());} else {updatePagination(1);}        

// Gestion de la pagination
$("#paginationsearch, #paginationsearchbottom").on('click', "[id^='paginationsearch_']:not([class=disabled]), [id^='paginationsearchbottom_']:not([class=disabled])", function() {
    if ($(this).hasClass('inactive')) { return false; }
    direction = this.id.replace('paginationsearch_', '');
    if (direction.indexOf('_') != -1 ) { direction = this.id.replace('paginationsearchbottom_', '');}

    switch (direction) {
        case ('first'):
            optionsRecherche['page'] = 1;
            break;
        case ('next'):
            optionsRecherche['page'] = parseInt($('#current_page').html()) + 1;
            break;
        case ('previous'):
            optionsRecherche['page'] = parseInt($('#current_page').html()) - 1;
            break;
        default:
            optionsRecherche['page'] = direction;
            break;
    }
    updatePage(optionsRecherche);
    return false;
});

if ($('#search_prixmensuel').length > 0) {
    // Permets la création des deux sliders (prix ttc et mensuel)
    // Utilisation de noUiSlider 
    // https://refreshless.com/nouislider/
    
    // Slider Prix Mensualité
    slidermensuel = document.getElementById('search_prixmensuel');
    // Initialisation des bornages
    prixmensuel_max = parseInt($('#search_prixmensuel_max').val());
    prixmensuel_min = parseInt($('#search_prixmensuel_min').val());
    if (prixmensuel_min == prixmensuel_max) {
        prixmensuel_min = prixmensuel_min - 10;
        prixmensuel_max = prixmensuel_max + 10;
    }

    if ($('#fromhome_prixmensuel_min').length > 0 && parseInt($('#fromhome_prixmensuel_min').html()) != prixmensuel_min) { start_prixmensuel_min = parseInt($('#fromhome_prixmensuel_min').html()); } else { start_prixmensuel_min = prixmensuel_min; }
    if ($('#fromhome_prixmensuel_max').length > 0 && parseInt($('#fromhome_prixmensuel_max').html()) != prixmensuel_max) { start_prixmensuel_max = parseInt($('#fromhome_prixmensuel_max').html()); } else { start_prixmensuel_max = prixmensuel_max; }
        

    noUiSlider.create(slidermensuel, {
        start: [start_prixmensuel_min, start_prixmensuel_max],
        step: 10,
        connect: true,
        range: {
            'min': prixmensuel_min,
            'max': prixmensuel_max
        },
        cssPrefix: 'prixmensuel-',
    });

    // Mets à jour les valeurs au dessus du slider en cas de modification
    slidermensuel.noUiSlider.on('set', function(values, handle) {
        var value = values[handle];
        // la présence de handle correspond ici au fait que cela soit la seconde tirette
        if (handle) {
            $("#search_prixmensuel_max").val(parseInt(value));
            $(".min-max-mensuel .float-right").html(parseInt(value) + '&euro; /mois');
        } else {
            $("#search_prixmensuel_min").val(parseInt(value));
            $(".min-max-mensuel .float-left").html(parseInt(value) + '&euro; /mois');
        }
    });
    
    // Hack pour le release sur le slider
    if (typeof slidermensuel !== "undefined") {

        // Hack pour le release sur le slider
        slidermensuel.noUiSlider.on('change', function(values, handle) {
            startclickslider = 1;
            eventclicked = handle;
            valueclicked = values;
        });
    }
    // Permets un collapse sur le filtre de recherche en mode mobile
    $('#display_recherche').on('hide.bs.collapse', function () {
        $('#recherche-display-caret').html('<i class="fas fa-angle-double-down"></i>');
    });
    $('#display_recherche').on('show.bs.collapse', function () {
        $('#recherche-display-caret').html('<i class="fas fa-angle-double-up"></i>');
    });
}

    $('#mobile_search_query').on('keypress',function(e) {
        if(e.which == 13) {
            var query = $('#mobile_search_query').val();
            optionsRecherche['search_string'] = query;
            $('.bootstrap-tagsinput > input').val(query);
            $('.bootstrap-tagsinput > input').tagsinput('add', query);
            $('.bootstrap-tagsinput > input').trigger(jQuery.Event('keypress', { keycode: 13 }));
            updatePage(optionsRecherche);

            $('#display_recherche').collapse('hide');
        }
    });

    $('#mobile_search_submit').on('click', function() {
        var query = $('#mobile_search_query').val();
        if (query != "") {
            optionsRecherche['search_string'] = query;
            $('.bootstrap-tagsinput > input').val(query);
            $('.bootstrap-tagsinput > input').tagsinput('add', query);
            $('.bootstrap-tagsinput > input').trigger(jQuery.Event('keypress', { keycode: 13 }));
            updatePage(optionsRecherche);

            $('#display_recherche').collapse('hide');
        }
    });

    $('#mobile_search_delete').on('click', function() {
        $('#mobile_search_query').val("");
        $('#mobile_search_submit').click();
        delete(optionsRecherche['search_string']);
        $('.bootstrap-tagsinput > input').val("");
        $('.bootstrap-tagsinput > input').trigger(jQuery.Event('keypress', { keycode: 13 }));
        $('.badge > span').each( function() { $(this).click();});
        updatePage(optionsRecherche);
        
        $('#display_recherche').collapse('hide');
    });


});