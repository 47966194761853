$(document).ready(function() {

    // Permets la création des deux sliders (prix ttc et mensuel)
    // Utilisation de noUiSlider 
    // https://refreshless.com/nouislider/
    if ($('#search_prixpublic').length > 0) {
        // Slider Prix Public
        slider = document.getElementById('search_prixpublic');
        // Initialisation des bornages
        prix_max = parseInt($('#search_prixpublic_max').val());
        prix_min = parseInt($('#search_prixpublic_min').val());
        if ($('#fromhome_prix_min').length > 0 && parseInt($('#fromhome_prix_min').html()) != prix_min) { start_prix_min = parseInt($('#fromhome_prix_min').html()); } else { start_prix_min = prix_min; }
        if ($('#fromhome_prix_max').length > 0 && parseInt($('#fromhome_prix_max').html()) != prix_max) { start_prix_max = parseInt($('#fromhome_prix_max').html()); } else { start_prix_max = prix_max; }

        noUiSlider.create(slider, {
            start: [start_prix_min, start_prix_max],
            step: 500,
            connect: true,
            range: {
                'min': prix_min,
                'max': prix_max
            },
            cssPrefix: 'prixttc-',
        });


        // Mets à jour les valeurs au dessus du slider en cas de modification
        slider.noUiSlider.on('end', function(values, handle) {
            var value = values[handle];
            // la présence de handle correspond ici au fait que cela soit la seconde tirette
            if (handle) {
                $("#search_prixpublic_max").val(parseInt(value));
                $(".min-max-ttc .float-right").html(parseInt(value) + ' &euro;');
            } else {
                $("#search_prixpublic_min").val(parseInt(value));
                $(".min-max-ttc .float-left").html(parseInt(value) + ' &euro;');
            }
        });

        // Slider Prix Mensualité
        slidermensuel = document.getElementById('search_prixmensuel');
        // Initialisation des bornages
        prixmensuel_max = parseInt($('#search_prixmensuel_max').val());
        prixmensuel_min = parseInt($('#search_prixmensuel_min').val());
        if (prixmensuel_min == prixmensuel_max) {
            prixmensuel_min = prixmensuel_min - 10;
            prixmensuel_max = prixmensuel_max + 10;
        }

        if ($('#fromhome_prixmensuel_min').length > 0 && parseInt($('#fromhome_prixmensuel_min').html()) != prixmensuel_min) { start_prixmensuel_min = parseInt($('#fromhome_prixmensuel_min').html()); } else { start_prixmensuel_min = prixmensuel_min; }
        if ($('#fromhome_prixmensuel_max').length > 0 && parseInt($('#fromhome_prixmensuel_max').html()) != prixmensuel_max) { start_prixmensuel_max = parseInt($('#fromhome_prixmensuel_max').html()); } else { start_prixmensuel_max = prixmensuel_max; }
            

        noUiSlider.create(slidermensuel, {
            start: [start_prixmensuel_min, start_prixmensuel_max],
            step: 10,
            connect: true,
            range: {
                'min': prixmensuel_min,
                'max': prixmensuel_max
            },
            cssPrefix: 'prixmensuel-',
        });

        // Mets à jour les valeurs au dessus du slider en cas de modification
        slidermensuel.noUiSlider.on('set', function(values, handle) {
            var value = values[handle];
            // la présence de handle correspond ici au fait que cela soit la seconde tirette
            if (handle) {
                $("#search_prixmensuel_max").val(parseInt(value));
                $(".min-max-mensuel .float-right").html(parseInt(value) + ' &euro; /mois');
            } else {
                $("#search_prixmensuel_min").val(parseInt(value));
                $(".min-max-mensuel .float-left").html(parseInt(value) + ' &euro; /mois');
            }
        });
    }
    // Hack pour le release sur le slider
    if (typeof slider !== "undefined") {
        slider.noUiSlider.on('change', function(values, handle) {
            startclickslider = 1;
            eventclicked = handle;
            valueclicked = values;
        });

        // Hack pour le release sur le slider
        slidermensuel.noUiSlider.on('change', function(values, handle) {
            startclickslider = 1;
            eventclicked = handle;
            valueclicked = values;
        });
    }
    
	// Page Home
	// 
	// if ($('.splide').length > 0) {
 //        new Splide( '.splide', {
 //            type   : 'loop',
 //            autoplay: true,
 //        } ).mount();
 //    }

    if ($('#splide_home').length > 0) {
        
        new Splide( '#splide_home', {
            type   : 'loop',
            autoplay: true,
            autoplaySpeed: 5000,
            //arrows: false,
            perPage: 4,
            pagination: false,
            breakpoints: {
                992: {
                    perPage: 3,
                },
                768: {
                    perPage: 2,
                },
                425: {
                    perPage: 1,
                }
            }
        } ).mount();
	}

    $('.img-rollover').on('mouseenter', function() {
        id = $(this).data('rollover');
        if ($('.rollover-'+id).is(":visible")) {$('.rollover-'+id).slideUp();}
        else {$('.rollover-'+id).slideDown();}
    })
    $('.img-rollover').on('mouseleave', function() {
        id = $(this).data('rollover');
        $('.rollover-'+id).slideUp();
    })


	// Page Contact
	// 
	if ($('.hidden-eml').length >0) {
		obfuscatedMail = decodeEmail($('.hidden-eml').html());
		$(".hidden-eml").html(obfuscatedMail);
		$(".hidden-eml-wrapper").attr("href", "mailto:"+obfuscatedMail);
	}

	// Contact téléphonique via le header
	// 
	$("#header-contact-submit").on('click', function() {
		callbackPhone = $('#header-contact-telephone').val();
		if (callbackPhone != '') {
            $.ajax({
                type: 'POST',
                url: siteURL + 'contact/fromFooter',
                data: {
                    'contact': callbackPhone
                },
                success: function(data) {
                	switch (data) {
                		case 'short' :
                			$('#errorMessage').html("Le numéro saisi semble être bien trop court, merci de vérifier avant de valider de nouveau.");
							$('#modalError').modal('show');
	                		break;
                		case 'letter' :
                			$('#errorMessage').html("Une lettre semble s'être cachée dans notre numéro de téléphone, merci de vérifier avant de valider de nouveau.");
							$('#modalError').modal('show');
    	            		break;
                		default:
                			// $('#contact-phone').html(callbackPhone);
                            $('#statut-base').hide();
							$('#statut-success').show(75);
        	        		break;
                	}
                },
                error: function() {
        			$('#errorMessage').html("Une erreur technique est survenue lors de l'envoi. Merci de passer par le biais du formulaire de contact pour demander à être rappelé.");
					$('#modalError').modal('show');
                }
            });
		}
		else {
			$('#errorMessage').html("Vraisemblablement vous avez validé une demande de rappel sans numéro de téléphone, merci de vérifier avant de valider de nouveau.");
			$('#modalError').modal('show');
		}
	});


	$("#page-contact-submit").on('click', function() {
        if ($('#vehicule_id').length > 0) {
            vehicule_contact = $('#vehicule_id').html();
        }
        else {
            vehicule_contact = '';
        }
        $.ajax({
	        type: 'POST',
	        url: 'contact/fromContactPage',
	        data: {
	            'contactName': $('#contactName').val(),
                'contactPrenom': $('#contactPrenom').val(),
                'contactSociete': $('#contactSociete').val(),
	            'contactMail': $('#contactMail').val(),
	            'contactPhone': $('#contactPhone').val(),
	            'contactSubject': $('#contactSubject option:selected ').text(),
	            'contactMessage': $('#contactMessage').val(),
                'contactType': $('#contactSubject option:selected ').val(),
                'contactVehiculeId' : vehicule_contact,
	        },
	        success: function(data) {
	        	switch (data) {
                    case 'missing_obligatory' :
                        $('#errorMessage').html("Vous n'avez pas rempli tous les champs obligatoire.");
                        $('#modalError').modal('show');
                        break;
	        		case 'missing_phone' :
                		$('#errorMessage').html("Vous n'avez pas précisé de numéro de téléphone dans le formulaire. Sans cela nous ne pouvons vous recontacter.");
						$('#modalError').modal('show');
	            		break;
	        		case 'missing_mail' :
                		$('#errorMessage').html("Vous n'avez pas précisé d'adresse email dans le formulaire. Sans cela nous ne pouvons vous recontacter.");
						$('#modalError').modal('show');
	            		break;
	        		default:
						$('#modalContactPage').modal('show');
		        		break;
	        	}
	        },
	        error: function() {
				$('#errorMessage').html("Une erreur technique est survenue lors de l'envoi. Merci de passer par le biais du formulaire de contact pour demander à être rappelé.");
				$('#modalError').modal('show');
	        }
    	});
	});

	$('#header-contact-telephone').pressEnter(function() { $("#header-contact-submit").trigger( "click" ); return false;})

 	$(".asterix-link").on('click', function() {
 		id = $(this).data('highlight');
 		$('#'+id).addClass('highlight');
 		setTimeout(function(){ $('#'+id).removeClass('highlight'); }, 2500);
 	})
});