$(document).ready(function() {

	if ($('#splide_results').length > 0) {
		new Splide( '#splide_results', {
			type   : 'loop',
            autoplay: true,
            pagination: false,
            arrows: false
		} ).mount();
	}
});