$(document).ready(function() {

	cocontractant = false;
	other_type = 'part';
	pages = {
		"1": 'situation',
		"2": 'adresse',
		"3": 'travail',
		"4": 'argent',
		"5": 'final',
	}

	$('body').on('click', '[id^="etes_"]', function() {
		type = this.id.replace('etes_', '');
		if (type == 'pro') {
			$('#etes_part').removeClass('active');
			$('#etes_pro').addClass('active');
			other_type = 'part';
			cocontractant = false;
			$('#final_usage').html('Professionel');
			//$('#formtravail_travail').attr('disabled', true);
			$('#formtravail_travail').append($('<option>', {
				value: 'pro',
    			text: 'Travailleur non salarié',
			}));
			$('#formtravail_travail').selectpicker('val', 'pro');
			$('#formtravail_secteur').append($('<option>', {
				value: 'pro',
    			text: 'Indépendant et Libéral',
			}));
			$('#formtravail_secteur').selectpicker('val', 'pro');
			$('.profession').show();

		} else {
			$('#etes_pro').removeClass('active');
			$('#etes_part').addClass('active');
			other_type = 'pro';
			$('#final_usage').html('Privé');
		}
		$('.only_'+type).each( function() {
			$(this).removeClass('d-none');
		});
		$('.only_'+other_type).each( function() {
			$(this).addClass('d-none');
		});
	});

	$('body').on('change', '[name="formsituation_cocontractant"]', function() {
		$('.cocontractant').each( function() {
			$(this).slideToggle();
		});
		if ($(this).val() == "oui") { cocontractant = true; } else { cocontractant == false;}
	});

	$('body').on('change', '#formtravail_travail, #formtravail_co_travail', function() {
		if (this.id == "formtravail_travail") { var travail = ".travail"; }
		else { var travail = ".cotravail";}
		if ($(this).val() == "CDI" || $(this).val() == "CDD") {
			$(travail).show();
		}
		else {
			$(travail).hide();
			$(travail).find('input, select').each( function() {
				$(this).val('');
			})
		}
	});

	$('body').on('change', '#final_atteste, #final_valide_info, #final_recontact, #formfinal_recontact_date, #formfinal_recontact_time', function() {
		if ($("#final_atteste").prop('checked') && $('#final_valide_info').prop('checked') && (($("input[name='final_recontact']:checked").val() == "true" && $("input[name='formfinal_recontact_date']").val() != "" && $("input[name='formfinal_recontact_time']").val() != "") || $("input[name='final_recontact']:checked").val() == "false")) {
			$('#gotosend').prop('disabled', false);
			$('#gotosend').addClass('active');
		}
		else {
			$('#gotosend').prop('disabled', true);
			$('#gotosend').removeClass('active');
		}
	})

	$('body').on('change', '#form_civilite', function() {
		if ($(this).val() == "Madame") {
			$('.onlymadame').each( function() {
				$(this).removeClass('d-none');
			});
		}
		else {
			$('.onlymadame').each( function() {
				$(this).addClass('d-none');
			});
		}
	});

	$('body').on('change', '#form_cocivilite', function() {
		if ($(this).val() == "Madame") {
			$('.onlycomadame').each( function() {
				$(this).removeClass('d-none');
			});
		}
		else {
			$('.onlycomadame').each( function() {
				$(this).addClass('d-none');
			});
		}
	});

	$('body').on('focus', '.niet', function () {
		$(this).removeClass('niet');
	});


	// SEND
	$('body').on('click', '#gotosend', function() {

		var results = {};
		
		if (cocontractant) { results['Présence d\'un co-contractant'] = "Oui";} else { results['Présence d\'un co-contractant'] = "Non";}
		if (other_type == "part") { results['Type de contractant'] = "Particulier";} else { results['Type de contractant'] = "Professionel";}

		for (var id in pages) { 
			$('[id^="form'+pages[id]+'"]').each( function() {
				if ((!cocontractant && !this.id.includes('_co_')) || cocontractant) {
					if ($(this).val() != "") {
						if ($(this).data('beautify')) { value = $(this).data('beautify'); } else { value = this.id;}
						results[value] = $(this).val();
					}
				}
			});
		}

		var form = new FormData();
		form.append('results', JSON.stringify(results));
		form.append('vehicule', $('#vehicule_id').html());
		form.append('loyer_mensuel', $('#loyer_mensuel').html());
		form.append('loyer_duree', $('#loyer_duree').html());
		form.append('loyer_apport', $('#loyer_apport').html());
		form.append('loyer_kilometrage', $('#loyer_kilometrage').html());
		$.ajax({
            type: 'POST',
            url: siteURL + 'formulaire/send',
            contentType: false,
            processData: false,
            data: form,
            success: function(data) {
            	$('.fancybox-close-small').click();
            	if (data == "ok") {
            		$.fancybox.open('<div>Votre demande a été envoyée<br/>Nous y répondrons au plus vite</div>');
            	} else {
            		$.fancybox.open('<div>Une erreur est survenue.<br/>Merci de nous contacter par un autre biais.</div>');
            	}
            }
        });
	});

	$('body').on('click', '[id^="gotopage_"], [id^="gobackpage_"]', function() {

		var back = false;
		var nop = false;

		id = this.id.replace('gotopage_', '');
		if (isNaN(id)) {
			id = this.id.replace('gobackpage_', '');
			back = true;
		}

		if (!back) {
			$('[id^="form'+pages[id - 1 ]+"_").each( function() {
				if($(this).prop('required') && (!this.id.includes("_co_") || ( this.id.includes("_co_") && cocontractant ))) {
					if ($(this).val() == "") {
						if ($('[data-id="'+this.id+'"]').length > 0) {
							$('[data-id="'+this.id+'"]').addClass("niet");
						}
						$(this).addClass("niet");
						nop = true;
					}
				}
			});
		}

		//back = true; //DEBUG
		if (!nop || back) {
			$('[id^="formpage_"]').each( function() {
			 	this_id = this.id.replace('formpage_', '');
			 	if (this_id != id) { $(this).hide();}
			 	else {$(this).show();}
			});
		}
	});

	$('body').on('click', '[id^="loa-mlclick-"]', function () {
		this_id = this.id.replace('loa-mlclick-', '').split("_");
		var id = this_id[0];
		var formule = this_id[1];
		$.fancybox.open( {
				src: siteURL + 'mentions-legales/'+id+'/'+formule,
				type: 'ajax',
				opts: {
					fullScreen : {
	    				autoStart: false
	  				},
				}
			} );
		return false;
	})

	$('body').on('click', '#loa-click', function() {
		id = $('.interaction-numero').html();
		if ($('.loa-choose-active').length > 0) {
			clicked = $('.loa-choose-active').attr('id').replace('loa-choose-','');
			$.fancybox.open( {
				src: siteURL + 'formulaire/'+id+'/'+clicked,
				type: 'ajax',
				opts: {
					afterShow : function( instance, current) {
						$('.selectpicker').selectpicker();
					},
					fullScreen : {
	    				autoStart: false
	  				},
	  				touch : false
				}
			} );
		}
	});

	// Recontact required la date et l'heure
	$('body').on("change", "input[name='final_recontact']", function() {
		if ($("input[name='final_recontact']:checked").val() == "true") {
			$("input[name='formfinal_recontact_date']").prop('required', true);
			$("input[name='formfinal_recontact_time']").prop('required', true);
		} else {
			$("input[name='formfinal_recontact_date']").prop('required', false);
			$("input[name='formfinal_recontact_time']").prop('required', false);
		}
	});

	$('body').on("keydown", "#formfinal_recontact_time", function() {
	    // Save old value.
	    if (!$(this).val() || (parseInt($(this).val()) <= 20 && parseInt($(this).val()) >= 8))
	    $(this).data("old", $(this).val());
  	});
  	$('body').on("keyup", "#formfinal_recontact_time", function() {
	    // Check correct, else revert back to old value.
	    if (!$(this).val() || (parseInt($(this).val()) <= 20 && parseInt($(this).val()) >= 8))
	      ;
	    else
	      $(this).val($(this).data("old"));
  	});

	// Affichage document téléchargé formulaire LLD
	$('.attachment input[type="file"]')
    .on('change', (event) => {
    let el = $(event.target).closest('.attachment').find('.btn-file');
    
    el
      .find('.btn-file__actions__item')
      .css({
        'padding': '15px'
      });
    
    el
      .find('.btn-file__preview')
      .css({
        'background-image': 'url(' + window.URL.createObjectURL(event.target.files[0]) + ')'
      });
 	});

	// Attendre affichage div
	$('.show-div1').delay(1000).fadeIn(1500);
	$('.show-div2').delay(1500).fadeIn(1500);
	$('.show-div3').delay(2000).fadeIn(1500);
	$('.show-div4').delay(2500).fadeIn(1500);
	$('.show-div5').delay(3000).fadeIn(1500);
	$('.show-div6').delay(3500).fadeIn(1500);
});

