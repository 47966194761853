$(document).ready(function() {
  // Lors d'un appui sur le bouton de financement on scroll sur l'onglet en question et on simule un click pour l'ouvrir
  $('body').on('click', '.interaction-simulateur', function() {
      $('div[data-display="annonce_caracteristiques"]').trigger('click');
      $('html,body').animate({scrollTop: $("#annonce-collateral").offset().top}, 'slow');
  });

  // Gère le slider sous l'image principale
  if ($('.annonce-moreimages').length != 0) {
    $('.annonce-moreimages').slick({
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 4500,
      initialSlide: 0,
      swipeToSlide: true
    });
  }
  

  // Si le véhicule est vendu/loué alors on affiche une modal l'indiquant
  if ($('#already_sold').length > 0) {
    $('#modalSold').modal();
  }
  // Et lorsque la modal est fermée on redirige vers le moteur de recherche
  // $('#modalSold').on('hide.bs.modal', function (e) {
  //  window.location = siteURL+"vehicules-leasing";
  // })

  // Choix de la formule de financement
  $('[id^="loa-choose-"]').on('click', function() {
    id = this.id.replace("loa-choose-", "");
    $('[id^="loa-choose-"]').each( function() {
      each_id = this.id.replace("loa-choose-", "");
      $('#loa-choose-input-'+each_id).attr('checked',false);
      $(this).removeClass('loa-choose-active');  
    });
    $(this).addClass('loa-choose-active');
    $('#loa-choose-input-'+id).attr('checked',true);
  })

  // Permets lors d'un click sur l'image du slider de remplacer l'image principale
  $('#page_vehicule_details').on('click', '.slider-refresh', function() {
      var url = $(this).attr('src');
      var clicked = $(this).attr('src').split('/thumbs/');

      $('.annonce-pictures').each(function() {
          if ($(this).is(':parent') && $(this).children().attr("src") == url.replace('thumbs', 'hd')) {
              $(this).children().trigger('click');
              return false;
          }
      });

      $('.annonce-pictures').each(function() {
          if ($(this).attr('href').includes(clicked[1]) == false) {
              $(this).empty();
          } else {
              $(this).html('<img id="annonce-zoom" class="lazyload" data-src="' + url.replace('thumbs', 'hd') + '"/>');
          }
      });
      $('#annonce-zoom').attr("src", url.replace('thumbs', 'hd'));
      $('#annonce-zoom').parent().attr("href", url.replace('thumbs', 'hd'));
  });

  // Gère les onglets Options/Equipement/Financement
  // $('.annonce-header').on('click', function() {
  //     if ($(this).hasClass('active')) { return false; }

  //     $('.annonce-header').each(function() { $(this).removeClass('active'); })
  //     $('.tab-accordion').each(function() { $(this).removeClass('fade in active show'); })
  //     $('#' + $(this).data('display')).addClass('fade in active show');
  //     $(this).addClass('active');
  // });
});